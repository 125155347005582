<template>
  <div class="card card-custom">
    <div class="card-header">
      <h4 class="card-title text-success">Подтверждение платежа</h4>
      <!-- <p class="card-subtitle">
        Для получения результата, пожалуйста проведите оплату в размере
        <b>2 рубля</b>
      </p> -->
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner
          label="Уточняем данные по платежу..."
          v-if="showSpinner"
        ></b-spinner>
        <p v-if="!showSpinner">
          Оплата успешна! Результат тестирования направлен на вашу почту! Можете
          закрыть эту страницу!
        </p>
      </div>
    </div>
    <div class="card-footer">
      <a
        href="https://chekr.ru/"
        target="_blank"
        class="text-dark-75 text-hover-primary"
      >
        Подготовлено с помощью сервиса Che(k)r.ru
      </a>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
import {
  SET_UUID,
  SET_FINISHED_ID,
  PURGE_CHECKLIST
} from "@/core/services/store/checklist.module";

export default {
  name: "payment",

  data() {
    return {
      statusId: null,
      showSpinner: true,
      paymentStatus: "pending",
      intervalID: null
    };
  },

  watch: {
    /* paymentStatus: function(val) {
      if (val === "paid") {
        clearInterval(this.IntervalID);
      }
    } */
  },

  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },

  mounted() {
    this.statusId = this.$route.query.id;
    //  console.log(this.statusId);
    if (typeof this.statusId !== "undefined" && this.statusId !== null) {
      this.statusId = parseInt(this.statusId, 10);
      // console.log(this.statusId);
      this.getPaymentStatus();
    }
  },

  methods: {
    getPaymentStatus() {
      this.intervalID = setInterval(() => {
        ApiService.post(`/api/v1/getPayment/`, {
          statusId: this.statusId
        })
          .then(({ data }) => {
            //    console.log(data);
            //     console.log(data.paid);
            if (data.paid) {
              this.showSpinner = false;
              this.paymentStatus = "paid";
              this.$store.commit(SET_UUID, null);
              this.$store.commit(SET_FINISHED_ID, null);
              this.$store.commit(PURGE_CHECKLIST);
              clearInterval(this.intervalID);
            }
          })
          .catch(err => {
            console.error(err);
          });
      }, 10000);
    }
  }
};
</script>
